import React, { useState } from 'react';
import Header from './components/Header';
import { Outlet, useLocation } from "react-router-dom";
import Footer from './components/Footer';
import banner from "../src/assets/images/startBanner.jpg";
import BotWidget from './components/BotWidget';

function App() {
  const location = useLocation();
  const [showBanner, setShowBanner] = useState(true);
  const [showAlert, setAlert] = useState(false);

  const handleBanner = () => {
    setShowBanner(false);
    setAlert(true);
  }

  const handleAlert = () => {
    setAlert(false);
  }

  return (
    <div className="app">
      {location.pathname === '/' && showBanner && (
        <div className="bg-gray-500 flex absolute w-full h-full justify-center items-center z-50 bg-opacity-50">
          <div className="bg-white w-[50%] gap-4 flex flex-col justify-center items-center">
            <img src={banner} alt="banner" className="object-cover" />
            <button onClick={handleBanner} className="bg-orange-500 text-white px-4 py-2 rounded-md mb-4">
              Continue
            </button>
          </div>
        </div>
      )}

      {showAlert && (
        <div className="bg-gray-500 flex absolute w-full h-full justify-center items-center z-50 bg-opacity-50">
          <div className="bg-white w-[50%] gap-4 flex flex-col justify-center items-center">
            <div style={{ backgroundColor: '#821cf4', color: '#ffcd51', justifyContent: 'center', padding: '15px', fontSize: '18px' }}>
              <b>
                <sapn>
                  As per amendment in section 200(3) of the Income-tax Act vide FINANCE (No.2) ACT,2024, no correction statement shall be delivered after the expiry of six years from the end of the financial year in which the statement referred to in sub-section (3) is required to be delivered. In view of the above, correction statements pertaining to Financial Year 2007-08 to 2018-19 shall be accepted only up-to 31st March 2025. Deductors/Collectors and other Stakeholders may kindly take note.
                </sapn>
                <br></br>
                <br></br>
                <span>
                  वित्त (संख्या 2) अधिनियम, 2024 के माध्यम से आयकरअधिनियम की धारा 200(3) मे संशोधनके अनुसार, वित्तीय वर्ष के अंतसे छह साल की समाप्ति के बाद कोई सुधार विवरण नहीं दिया जाएगा जिसमें उप-धारा (3) में निर्दिष्ट विवरण दिया जाना आवश्यक है।उपरोक्तको ध्यान में रखते हुए, वित्तीय वर्ष 2007-08 से 2018-19 से संबंधित सुधार विवरण केवल 31 मार्च 2025 तक स्वीकार किए जाएंगे। कटौतीकर्ता/कलेक्टर और अन्य हितधारक कृपया इस पर ध्यान दें।
                </span>
              </b>
            </div>
            <button onClick={handleAlert} className="bg-orange-500 text-white px-4 py-2 rounded-md mb-4">
              Continue
            </button>
          </div>
        </div>
      )}
      {/* <BotWidget /> */}
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
import React, { useState } from "react";
import CorrectionTable from "../../../components/CorrectionTable";

const FAQComponent = () => {
  const [activeTab, setActiveTab] = useState("PAN");
  const [openFAQ, setOpenFAQ] = useState(null);
  const linkData = {
    PAN: [
      {
        text: "What is PAN?",
        content: "Permanent Account Number (PAN) is a ten-digit alphanumeric identifier, issued by Income Tax Department. Each assessee (e.g. individual, firm, company etc.) is issued a unique PAN.",
      },
      {
        text: "Who can apply for PAN?",
        content: "All existing assessees of taxpayers or persons who are required to file a return of income, even on behalf of others, must have a PAN. Any person, who intends to enter into economic or financial transactions where quoting PAN is mandatory, must also have a PAN.",
      },
      {
        text: "Who can apply on behalf of minor, lunatic, idiot, mentally retarded, deceased and wards of court?",
        content: "In such cases:\n\n- In the application for PAN, details of the minor, lunatic, idiot, mentally retarded, deceased, wards of court, etc. should be provided.\n- Details of representative assessee have to be provided in item 14 of the application for PAN.",
      },
      {
        text: "Is it compulsory to quote PAN on 'return of income'?",
        content: "Yes, it is compulsory to quote PAN on return of income.",
      },
      {
        text: "Do I need to apply for a new PAN when I move from one city to another?",
        content: "No, Permanent Account Number (PAN), as the name suggests, is a permanent number and does not change. Changing the address though, may change the Assessing Officer.",
      },
      {
        text: "Can I have more than one PAN?",
        content: "No. Obtaining/possessing more than one PAN is against the law and may attract a penalty up to ₹ 10,000.",
      },
      {
        text: "What should I do if I have more than one PAN?",
        content: "You may fill and submit PAN Change Request application form by mentioning the PAN which you are using currently on top of the form. All other PANs inadvertently allotted to you should be mentioned at item no. 11 of the form and the corresponding PAN card copies should be submitted for cancellation along with the form.",
      },
      {
        text: "Is there any third party verification conducted to verify identity and address of PAN applicants along with genuineness of documents submitted by them?",
        content: "Yes. As per procedure prescribed by Income Tax Department, third party verification may be conducted to verify identity and address of PAN applicants along with genuineness of documents submitting by them during PAN application.",
      },
      {
        text: "What is e-PAN? Is e-PAN a valid proof of allotment of PAN?",
        content: "e-PAN is a digitally signed PAN card issued in electronic form and it is a valid proof of allotment of PAN.",
      },
      {
        text: "Is email ID mandatory for receiving e-PAN?",
        content: "Yes, it is mandatory to mention valid e-mail ID in PAN application form to receive e-PAN.",
      },
      {
        text: "Is it compulsory to link Aadhaar with PAN?",
        content: "Section 139AA of the Income Tax Act states that every person who has been allotted PAN as on July 1, 2017 is required to link his/her PAN with his/her Aadhaar number.",
      },
      {
        text: "What is the deadline to link Aadhaar with PAN?",
        content: "Aadhaar-PAN linking deadline has been extended till March 31, 2022.",
      },
      {
        text: "How do I link my Aadhaar with PAN?",
        content: "(A) You may visit e-filing website of Income Tax Department https://www.incometax.gov.in/iec/foportal or Protean e-Gov TIN website www.protean-tinpan.com and link your Aadhaar with PAN.\n\n(B) Alternatively, you can link your Aadhaar with PAN by sending SMS in the following format to 567678:\nUIDAIPAN<SPACE><12 digit Aadhaar><SPACE><10 digit PAN>",
      },
      {
        text: "What should I do if I am unable to link my Aadhaar with PAN either online or through SMS facility as mentioned above?",
        content: "You can link your Aadhaar with PAN using Biometric Aadhaar Authentication services. Click here to download Aadhaar Seeding Request Form and visit designated PAN center for Biometric Aadhaar Authentication along with supporting documents i.e. copy of PAN card and Aadhaar card.",
      },
    ],
    TAN: [
      {
        text: "What is TAN?",
        content: "TAN or Tax Deduction and Collection Account Number is a 10 digit alpha numeric number required to be obtained by all persons who are responsible for deducting or collecting tax. It is compulsory to quote TAN in all TDS/TCS returns (including e-TDS/TCS return), TDS/TCS payment challans and TDS/TCS certificates.",
      },
      {
        text: "Why is it necessary to have TAN?",
        content: "The provisions of Section 203A of the Income-tax Act, 1961 require all persons who deduct or collect tax at source to apply for the allotment of TAN. The section also makes it mandatory to quote TAN in all TDS/TCS/Annual Information Returns, payment challans and certificates to be issued. If TAN is not quoted, TDS/TCS returns will not be accepted by TIN-Facilitation Centres (TIN-FCs) and challans for TDS/TCS payments will not be accepted by banks. Failure to apply for TAN or not quoting the same in the specified documents attracts a penalty of Rs. 10,000.",
      },
      {
        text: "Who must apply for TAN?",
        content: "All those persons who are required to deduct/collect tax at source on behalf of Income Tax Department are required to apply for and obtain TAN.",
      },
    ],
    "e-TDS/TCS": [
      {
        text: "What is quarterly e-TDS/TCS statement?",
        content: "TDS/TCS returns filed in electronic form as per section 200(3)/206C, as amended by Finance Act, 2005, are quarterly TDS/TCS statements. As per the Income Tax Act, these quarterly statements are required to be furnished from FY 2005-06 onwards; however, as advised by Income Tax Department, acceptance of e-TDS/TCS statements pertaining to Financial Years prior to 2007-08 has been discontinued at TIN. The forms used for quarterly e-TDS statements Forms are 24Q, 26Q and 27Q and for quarterly e-TCS statement is Form No. 27EQ. These statements filed in CD/Pen Drive should be accompanied by a signed verification in Form No. 27A in case of both, e-TDS/TCS statements.",
      },
      {
        text: "Who is required to file e-TDS/TCS statements?",
        content: "As per Income Tax Act, 1961, all corporate and government deductors/collectors are compulsorily required to file their TDS/TCS statements in electronic form i.e. e-TDS/TCS returns. However, deductors/collectors other than corporate/government can file either in physical or in electronic form.",
      },
      {
        text: "e-TDS/TCS statement have been made mandatory for Government deductors. How do I know whether I am a Government deductor or not?",
        content: "All Drawing and Disbursing Officers of Central and State Governments come under the category of Government deductors.",
      },
      {
        text: "Under what provision should e-TDS/TCS statement be filed?",
        content: "An e-TDS statement should be filed under Section 206 of the Income Tax Act in accordance with the scheme dated August 26, 2003 for electronic filing of TDS statement notified by the Central Board of Direct Taxes (CBDT) for this purpose. CBDT Circular No. 8 dated September 19, 2003 may also be referred. An e-TCS statement should be filed under Section 206C of the Income Tax Act in accordance with the scheme dated March 30, 2005 for electronic filing of TCS return notified by the CBDT for this purpose. As per section 200(3)/206C, as amended by Finance Act, 2005, deductors/collectors are required to file quarterly TDS/TCS statements from FY 2005-06 onwards.",
      },
      {
        text: "Who is the e-Filing Administrator?",
        content: "CBDT has appointed the Director General of Income Tax (Systems) as e-Filing Administrator for the purpose of electronic filing of TDS/TCS statement.",
      },
      {
        text: "Who is an e-TDS/TCS intermediary?",
        content: "CBDT has appointed Protean e-Gov Technologies Limited, (Protean), Mumbai, as e-TDS/TCS intermediary. Protean has established TIN Facilitation Centres (TIN-FCs) across the country to facilitate deductors/collectors file their e-TDS/TCS statement.",
      },
      {
        text: "Can we get details about foreign remittance (Form 15CA)? Can we get re-print of our Form 15CA?",
        content: "With effect from February 12, 2014, functionality of furnishing the foreign remittance details in Form 15CA and its related features have been discontinued from TIN and same are made available on the e-filing portal of Income Tax Department www.incometaxindiaefiling.gov.in",
      },
      {
        text: "Restrictions pertaining to filing of correction statements (Applicable from 1st April 2025)",
        content: (
          <>
            <p style={{ marginBottom: "16px" }}>
            As per the amendment in section 200(3) of the Income-tax Act vide FINANCE (No.2) ACT,2024, no correction statement shall be delivered after the expiry of six years from the end of the financial year in which the statement referred to in sub-section (3) is required to be delivered. In view of the above, correction statements pertaining to Financial Year 2007-08 to 2018-19 shall be accepted only up-to 31st March 2025. 
            </p>
            <ul>
  <li style={{ marginBottom: "16px" }}>
    - No TDS/TCS correction statement will be allowed after the expiry of six years from the end of the financial year in which the original statement was filed.
  </li>
  <li style={{ marginBottom: "16px" }}>
     - For quarter 1, 2 and 3, corrections can be made within six subsequent financial years. For Q4, corrections can be filed within six subsequent financial years plus one additional year as the filing due date for Q4 fall in subsequent financial year.
  </li>
  <li style={{ marginBottom: "16px" }}>
    - This change is applicable from 1st April 2025 and to all forms 24Q, 26Q, 27Q, and 27EQ.
  </li>
</ul>

<p style={{ marginBottom: "16px" }}><strong>For Instance:</strong></p>

            <CorrectionTable /> {/* Render the table here */}
          </>
        ),
      },
    ],
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setOpenFAQ(null);
  };

  const handleFAQClick = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const half = Math.ceil(linkData[activeTab].length / 2);
  const leftColumn = linkData[activeTab].slice(0, half);
  const rightColumn = linkData[activeTab].slice(half);

  return (
    <section className="w-full flex flex-col relative lg:px-0 bg-black lg:py-10">
      {/* Tabs */}
      <div className="flex px-[22px] lg:px-[70px] gap-2 items-center w-full py-4">
        <svg
          width="34"
          height="17"
          viewBox="0 0 34 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
            fill="#54AD47"
          />
          <path
            d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
            fill="#54AD47"
          />
          <circle cx="25.5" cy="8.5" r="8.5" fill="#54AD47" />
        </svg>
        <h3 className="font-semibold text-white text-[26px]">FAQ’s</h3>
      </div>
      <div className="lg:px-[70px]">
        {/* Mobile Dropdown */}
        <div className="block lg:hidden my-4 px-[22px] ">
          <select
            value={activeTab}
            onChange={(e) => handleTabClick(e.target.value)}
            className="w-full p-2 border-[1px] border-[#514B4B] bg-black text-white rounded-md"
          >
            <option value="PAN">PAN</option>
            <option value="TAN">TAN</option>
            <option value="e-TDS/TCS">e-TDS/TCS</option>
          </select>
        </div>

        {/* Desktop Tabs */}
        <div className="hidden lg:flex justify-between items-center border-b border-gray-600 mb-4">
          {["PAN", "TAN", "e-TDS/TCS"].map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`text-white py-2 px-2 lg:min-w-[384px] focus:outline-none ${
                activeTab === tab ? "border-b-2 border-[#54AD47]" : ""
              }`}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Content */}
        <div className="flex flex-col lg:flex-row lg:space-x-8 px-[22px] lg:px-[70px] py-4 ">
          <div className="flex-1">
            {leftColumn.map((item, index) => (
              <div key={index} className="mt-4">
                <button
                  onClick={() => handleFAQClick(index)}
                  className="flex justify-between items-center text-left w-full p-2 border-b border-gray-600 text-white"
                >
                  {item.text}
                  <span>
                    {openFAQ === index ? (
                      <svg
                        width="24"
                        height="24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-up"
                      >
                        <polyline points="18 15 12 9 6 15"></polyline>
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-down"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    )}
                  </span>
                </button>
                {openFAQ === index && (
                  <div className="p-2 text-white bg-black  whitespace-pre-line">{item.content}</div>
                )}
              </div>
            ))}
          </div>
          <div className="flex-1">
            {rightColumn.map((item, index) => (
              <div key={index} className="mt-4">
                <button
                  onClick={() => handleFAQClick(index + half)}
                  className="flex justify-between items-center text-left w-full p-2 border-b border-gray-600 text-white"
                >
                  {item.text}
                  <span>
                    {openFAQ === index + half ? (
                      <svg
                        width="24"
                        height="24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-up"
                      >
                        <polyline points="18 15 12 9 6 15"></polyline>
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-down"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    )}
                  </span>
                </button>
                {openFAQ === index + half && (
                  <div className="p-2 text-white bg-black whitespace-pre-line">{item.content}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQComponent;

// ReturnsAds.jsx
import React, { useState } from "react";
import FourCards from "../../../components/FourCards";

const ReturnsAds = () => {
  const steps = [
    {
      title: "",
      description:
        "The data structure (file format) in which the e-TDS / e-TCS return is to be prepared has been notified below:",
      cards: [
        {
          title: "Quarterly Returns",
          subTitle: "For regular statements pertaining to FY 2010-11 onwards:",
          firstColumn: [
            {
              text: "File Format for Form 24Q Q1 to Q3 Version 6.2",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q1_to_Q3_Version_6.2_01062023_201011.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "File Format for Form 24Q (4th Quarter) Version 7.4 ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q4_Version_7.4_06112024_201112.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "File Format for Form 26Q Q1 TO Q4 Version 7.7",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Regular_Q1_to_Q4_Version_7.7_04022025_201011.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
          ],
          secondColumn: [
            {
              text: "File Format for Form 27EQ Q1 to Q4 Version 6.8",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Regular_Q1_to_Q4_Version_6.8_ 06112024_201011.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "File Format for Form 27Q Q1 to Q4 Version 7.4",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Regular_Q1_to_Q4_Version_7.4_14082024_201011.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
          ],
        },
        {
          title: "For regular statements up to FY 2009-10:",
          subTitle: "",
          firstColumn: [
            {
              text: "File Format for Form 24Q Q1 to Q3 Version 5.8",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q1_to_Q3_Version_5.8_01062023_200910.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "File Format for Form 24Q (4th Quarter) Version 6.2",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q4_Version_6.2_01062023_200910.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "File Format for Form 26Q Q1 TO Q4 Version 5.8",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Regular_Q1_to_Q4_Version_5.8_01062023_200910.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
          ],
          secondColumn: [
            {
              text: "File Format for Form 27EQ Q1 to Q4 Version 5.8",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Regular_Q1_to_Q4_Version_5.8_%2001062023_200910.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "File Format for Form 27Q Q1 to Q4 Version 5.8",
              href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Regular_Q1_to_Q4_Version_5.8_01062023_200910.xls",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
          ],
        },
        // {
        //   title: "For correction statements up to FY 2009-10:",
        //   subTitle: "",
        //   firstColumn: [
        //     {
        //       text: "File Format for Form 24Q correction Q1 to Q3 Version 6.1",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q1_Q3_Version_6.1_01062023_200910.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //     {
        //       text: "File Format for Form 24Q correction (4th Quarter) Version 6.3",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q4-Version_6.3_01062023_200910.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //     {
        //       text: "File Format for Form 26Q correction Q1 to Q4 Version 6.1",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Correction_Q1_Q4_Version_6.1_01062023_200910.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //   ],
        //   secondColumn: [
        //     {
        //       text: "File Format for Form 27EQ correction Q1 to Q4 Version 6.1",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Correction_Q1_Q4_Version_6.1_01062023_200910.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //     {
        //       text: "File Format for Form 27Q correction Q1 to Q4 Version 6.0",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Correction_Q1_Q4_Version_6.0_01062023_200910.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //   ],
        // },
        // {
        //   title: "For correction statements pertaining to FY 2010-11 onwards:",
        //   subTitle: "",
        //   firstColumn: [
        //     {
        //       text: "File Format for Form 24Q correction Q1 to Q3 Version 7.3",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q1_to_Q3_Version_7.3_04022025_201012.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //     {
        //       text: "File Format for Form 24Q correction (4th Quarter) Version 7.6",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q4_Version_7.6_04022025_201011.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //     {
        //       text: "File Format for Form 26Q correction Q1 to Q4 Version 7.9",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Correction_Q1_to_Q4_Version_7.9_04022025_201011.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //   ],
        //   secondColumn: [
        //     {
        //       text: "File Format for Form 27EQ correction Q1 to Q4 Version 7.4",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Correction_Q1_to_Q4_Version_7.4_04022025_201011.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //     {
        //       text: "File Format for Form 27Q correction Q1 to Q4 Version 7.7",
        //       href: "https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Correction_Q1_to_Q4_Version_7.7_04022025_201011.xls",
        //       style: { linkMaxWidth: "187px", textColor: "black" },
        //     },
        //   ],
        // },
      ],
    },
    {
      title: "",
      description:
        "e-TDS/e-TCS return in accordance with the file formats is to be prepared in clean text ASCII format with 'txt' as filename extension. e-TDS/e-TCS return can be prepared using in-house software, any other third party software or the Protean (formerly NSDL eGov) TDS Return Preparation Utility.",
      cards: [
        {
          title: "Quarterly Returns",
          subTitle: "For statement pertaining to FY 2010-11 onwards::",
          firstColumn: [
            {
              text: "Sample file for Form 24Q (1st, 2nd & 3rd Quarter) ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/24QQ3.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "Sample file for Form 24Q (4th Quarter) ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/24QQ4.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "Sample file for Form 26Q ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/26QQ1.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
          ],
          secondColumn: [
            {
              text: "Sample file for Form 27Q ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/27QQ2.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "Sample file for Form 27EQ ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/27EQRQ3.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
          ],
        },
        {
          title: "For statement upto FY 2009-10",
          subTitle: "",
          firstColumn: [
            {
              text: "Sample file for Form 24Q (1st, 2nd & 3rd Quarter) ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/24QRQ1.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "Sample file for Form 24Q (4th Quarter) ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/24QRQ4.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "Sample file for Form 26Q ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/26QRQ1.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
          ],
          secondColumn: [
            {
              text: "Sample file for Form 27Q ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/27QRQ1.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "Sample file for Form 27EQ ",
              href: "https://www.protean-tinpan.com/downloads/e-tds/27EQRQ1.zip",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
          ],
        },
      ],
    },
    {
      title: "",
      description:
        "Once the file has been prepared as per the file format, it should be verified using the File Validation Utility (FVU) provided by Protean e-Gov.:",
      cards: [
        {
          title: "Quarterly Returns",
          subTitle: "For regular statements pertaining to FY 2010-11 onwards:",
          firstColumn: [
            {
              text: "FVU for Quarterly Returns: e-TDS / e-TCS returns prepared upto FY 2009-10 (i.e. Forms 24Q, 26Q, 27Q and 27EQ) can be validated using this utility.",
              href: "https://www.protean-tinpan.com/services/etds-etcs/etds-download-regular.html",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
            {
              text: "FVU for Quarterly Returns: e-TDS / e-TCS returns prepared for FY 2010-11 and onwards (i.e. Forms 24Q, 26Q, 27Q and 27EQ) can be validated using this utility.",
              href: "https://www.protean-tinpan.com/services/etds-etcs/etds-download-regular.html",
              style: { linkMaxWidth: "187px", textColor: "black" },
            },
          ],
          secondColumn: [],
        },
      ],
    },
    {
      title: "",
      description:
        "In case file has any errors the FVU will give a report of the errors. Rectify the errors and verify the file again through the FVU. ",
      cards: [],
    },
    {
      title: "",
      description:
        "The upload file generated by the FVU on successful validation is to be furnished to a TIN-FC. Quarterly Return : Each e-TDS/TCS return saved in a CD/Pen Drive to be submitted along with a signed copy of the control chart (Form 27A). With effect from February 1, 2014, it is mandatory to submit Form 27A generated by TDS/TCS FVU (File Validation Utility) duly signed, along with the TDS/TCS statement(s). Any other Form 27A submitted will be treated as invalid submission and the same will be rejected by TIN-FC branches. (Form 27A).",
      cards: [],
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  return (
    <section className="px-4 lg:px-20 flex flex-col">
      {/* Tabs */}
      <div className="flex justify-around border-b border-gray-200 mb-8">
        {steps.map((step, index) => (
          <button
            key={index}
            onClick={() => setActiveStep(index)}
            className={`py-2 px-4 font-semibold ${activeStep === index
              ? "text-[#54AD47] border-b-2 border-[#54AD47]"
              : "text-gray-500"
              }`}
          >
            Step {index + 1}
          </button>
        ))}
      </div>

      {/* Content */}
      <div>
        {steps[activeStep] && (
          <div className="flex flex-col gap-4">
            <h3 className="text-[18px] lg:text-[20px] font-semibold mb-4">
              {steps[activeStep].title}
            </h3>
            {steps[activeStep].description && (
              <p className="text-[14px] lg:text-[15px] mb-4">
                {steps[activeStep].description}
              </p>
            )}
            <div className="flex justify-center">
              <FourCards
                className="custom-class "
                data={steps[activeStep].cards}
                maxWidthDesktop="600px"
                minWidthDesktop="640px"
              />
            </div>
            <div>
              <table className="w-full text-center border-collapse">
                <thead>
                  <tr>
                    <th className="px-4 py-2 bg-[#edf7ec]">For correction statements</th>
                    <th className="px-4 py-2 bg-[#edf7ec]"></th>
                  </tr>
                </thead>
                <tbody className="block w-full lg:table-row-group text-center">

                  <tr className="block  border border-gray-300 p-4 flex flex-col lg:table-row">
                    <td className="px-4 py-2">
                      <a
                        href="https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q1_to_Q3_Version_7.3_04022025_201012.xls"
                        className="text-[#634B83]"
                      >
                        File Format for Form 24Q correction Q1 to Q3 Version 7.3
                      </a>
                    </td>

                    <td className="px-4 py-2">
                      <a
                        href="https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q4_Version_7.6_04022025_201011.xls"
                        className="text-[#634B83]"
                      >
                        File Format for Form 24Q correction (4th Quarter) Version 7.6
                      </a>
                    </td>
                  </tr>
                  <tr className="block lg:table-row border border-gray-300 p-4 flex flex-col lg:table-row">
                    <td className="px-4 py-2">
                      <a
                        href="https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Correction_Q1_to_Q4_Version_7.9_04022025_201011.xls"
                        className="text-[#634B83]"
                      >
                        File Format for Form 26Q correction Q1 to Q4 Version 7.9
                      </a>
                    </td>

                    <td className="px-4 py-2" colSpan="4">
                      <a
                        href="https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Correction_Q1_to_Q4_Version_7.4_04022025_201011.xls"
                        className="text-[#634B83]"
                      >
                        File Format for Form 27EQ correction Q1 to Q4 Version 7.4
                      </a>
                    </td>
                  </tr>
                  <tr className="block lg:table-row border border-gray-300 p-4">
                    <td className="px-4 py-2" >
                      <a
                        href="https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Correction_Q1_to_Q4_Version_7.7_04022025_201011.xls"
                        className="text-[#634B83]"
                      >
                        File Format for Form 27Q correction Q1 to Q4 Version 7.7
                      </a>
                    </td>
                    <td className="px-4 py-2" >

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ReturnsAds;
